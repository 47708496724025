import { v4 as uuidv4 } from 'uuid';
import {
  Reason,
  Status,
} from '@wix/ambassador-file-sharing-v1-library-item/build/cjs/types.impl';
import webBiLogger from '@wix/web-bi-logger';
import {
  fileShareNewFileClick,
  fileShareNewFileCreated,
  fileShareUploadButtonState,
} from '@wix/bi-logger-wixlabs-users/v2';
import model, {
  getSupportedExtensions,
  uploadFile,
  getFolderUploadPermissions,
  queryFoldersApi,
  getListRoles,
  getFileshareSettings,
} from './model';
import { fileSize, roles } from '../../constants/constants';
import { fixTrim, asyncForEach } from '../helpers/helpersFunctions';
import ElementsVisibility from '../helpers/elementsVisibility';
import { chunk } from 'lodash';
import { UploadStatus } from '../../types';

const handleUploadPermissions = async ($w, flowAPI, selectedFolderId) => {
  const permission = await getFolderUploadPermissions(
    flowAPI,
    selectedFolderId,
  );
  try {
    const customElement = $w('#uploadCustomElement');
    if (permission?.status === Status.FORBIDDEN) {
      customElement.setAttribute('canClick', 'false');
    } else {
      customElement.setAttribute('canClick', 'true');
    }
  } catch (e) {
    console.log('failed to setattribute to custom element', e);
  }
  return permission;
};

export default model.createController(({ $bind, $widget, flowAPI }) => {
  let selectedFolderId: string;
  let showDestinationFolder: boolean;
  let hideWhenNoPermissions: boolean;
  let permission;
  let selectedFolderName;
  let uploadsInProgress = 0;
  const {
    translations: { t },
  } = flowAPI;
  let firstClick = true;
  const { controllerConfig } = flowAPI;
  const { wixCodeApi } = controllerConfig;
  const userAPI = controllerConfig.wixCodeApi.user;
  const isAPIAllowed = flowAPI.environment.isViewer;
  const logger = webBiLogger.factory().logger();
  let multiUploadSuccesFiles = [];
  let supportedExtensions: any = [];
  let iInterval;
  let isMultiError = false;

  $widget.onPropsChanged((oldProps, newProps) => {
    if (JSON.stringify(oldProps) !== JSON.stringify(newProps)) {
      selectedFolderId = newProps.selectedFolderId;
      showDestinationFolder = newProps.showDestinationFolder;
      hideWhenNoPermissions = newProps.hideWhenNoPermissions;
    }
  });

  let handleElementsUploadMultiFilesCalled = false;
  class createControllerFromReady {
    $w;
    elementsVisibility;
    hideAndCollapseElements;
    showAndExpandElements;
    openPopoverFiles;
    timers;
    constructor($w) {
      this.$w = $w;
      this.openPopoverFiles = false;
      this.elementsVisibility = new ElementsVisibility($w);
      this.showAndExpandElements =
        this.elementsVisibility.showAndExpandElements;
      this.hideAndCollapseElements =
        this.elementsVisibility.hideAndCollapseElements;
      this.timers = { multiple: null, single: null };
    }
    emptyFilesUploadList = () => {
      if (uploadsInProgress) {
        return;
      }
      const allUploaded = multiUploadSuccesFiles.every(
        (file) => file.upload === UploadStatus.Finish || false,
      );
      if (allUploaded) {
        multiUploadSuccesFiles = [];
      }
    };
    hideUploadSuccesafterTime = (seconds) => {
      clearTimeout(this.timers.single);
      this.emptyFilesUploadList();
      this.timers.single = setTimeout(() => {
        if (!uploadsInProgress) {
          this.emptyFilesUploadList();
          this.$w('#SingleFile').hide('fade');
          this.$w('#tooltip').hide('fade');
        }
      }, seconds * 1000);
    };
    hideUploadMultiSuccesAfterTime = (seconds) => {
      clearTimeout(this.timers.multiple);
      this.emptyFilesUploadList();
      this.timers.multiple = setTimeout(() => {
        if (!uploadsInProgress) {
          this.emptyFilesUploadList();
          handleElementsUploadMultiFilesCalled = false;
          isMultiError = false;
          this.$w('#MultiFiles').hide('fade');
          this.$w('#tooltip').hide('fade');
        }
      }, seconds * 1000);
    };
    hideUploadErrorafterTime = (seconds) => {
      clearTimeout(this.timers.single);
      this.emptyFilesUploadList();
      this.timers.single = setTimeout(() => {
        if (!uploadsInProgress) {
          this.emptyFilesUploadList();
          this.$w('#SingleFile').hide('fade');
          this.$w('#tooltip').hide('fade');
        }
      }, seconds * 1000);
    };
    onUploadProgress = async (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent >= 100) {
        if (iInterval) {
          clearInterval(iInterval);
        }
      }
    };
    singleFileUploadResetElements = (name, shortName) => {
      this.$w('#SingleFile').changeState('SingleLoading');
      this.$w('#text2').text = shortName;
      this.$w('#text10').text = name;
      this.showAndExpandElements([
        'text2',
        'SingleFile',
        'SingleLoading',
        'imageX1',
        'wrapContainer',
      ]);
    };

    singleUploadSuccessDisplay = (shortName, name) => {
      this.$w('#SingleFile').changeState('SingleSuccess');
      this.showAndExpandElements([
        'SingleSuccess',
        'collapsibleText1',
        'vectorImage4',
      ]);
      this.$w('#collapsibleText1').text = shortName;
      this.$w('#text10').text = name;

      if (showDestinationFolder) {
        this.showAndExpandElements(['ShowFolderName']);
        this.$w('#ShowFolderName').text = t(
          'app.widget.upload.success.folder',
          {
            selectedFolderName,
          },
        );
      }
      this.hideUploadSuccesafterTime(20);
    };
    checkBeforeUploadError = ({ name, extension, size }): Boolean => {
      let response = true;
      const { shortName } = fixTrim(name, 20);
      this.showAndExpandElements([
        'SingleFile',
        'SingleError',
        'text3',
        'text4',
      ]);
      this.$w('#text10').text = name;
      if (
        name.indexOf('.') === -1 ||
        !supportedExtensions.includes(extension)
      ) {
        response = false;
        this.$w('#text3').text = t(
          'app.widget.upload.error.extenstion.single.folder',
          { fileType: extension },
        );
      } else if (size > fileSize.maxSize) {
        response = false;
        this.$w('#text3').text = t(
          'app.widget.upload.error.size.single.folder',
        );
      }
      this.$w('#text4').text = shortName;
      this.hideUploadErrorafterTime(20);
      return response;
    };
    singleUploadErrorConnectionDisplay = ({ name }) => {
      this.$w('#SingleFile').changeState('SingleError');
      const { shortName } = fixTrim(name, 20);
      this.showAndExpandElements([
        'SingleFile',
        'SingleError',
        'text3',
        'text4',
      ]);
      this.$w('#text3').text = t(
        'app.widget.upload.error.connection.single.folder',
      );
      this.$w('#text4').text = shortName;
      this.hideUploadErrorafterTime(20);
    };
    handleSingleFileReaderLoaded = async ({
      myFile,
      shortName,
      extension,
      name,
    }) => {
      if (
        !this.checkBeforeUploadError({ name, extension, size: myFile.size })
      ) {
        this.$w('#SingleFile').changeState('SingleError');
        myFile.upload = UploadStatus.Finish;
        myFile.error = true;
        return;
      }
      clearTimeout(this.timers.single);
      const blob = new Blob([myFile as BlobPart], {
        type: myFile.type,
      });
      myFile._id = myFile._id || uuidv4();
      myFile.upload = UploadStatus.Progress;
      myFile.error = false;
      uploadsInProgress++;
      const successfulUpload = await uploadFile(
        flowAPI,
        {
          name,
          size: myFile.size,
          myFile: blob,
        },
        selectedFolderId,
        this.onUploadProgress,
      );
      myFile.upload = UploadStatus.Finish;
      uploadsInProgress--;
      this.$w('#text2').hide();
      if (successfulUpload) {
        logger.report(
          fileShareNewFileCreated({
            app_id: controllerConfig.appParams.appDefinitionId,
            app_site_id: controllerConfig.appParams.appInstanceId,
            description: 'load',
            file_id: myFile.id,
            file_name: myFile.name,
            file_owner: userAPI.currentUser.role,
            file_size: myFile.size,
            file_type: myFile.type,
            instance_id: controllerConfig.appParams.instanceId,
            is_camera: false,
            is_first: firstClick,
            biToken: controllerConfig.platformAPIs.bi.metaSiteId,
            owner: userAPI.currentUser.role,
          }),
        );
        this.singleUploadSuccessDisplay(shortName, name);
      }

      if (!successfulUpload) {
        myFile.error = true;
        this.singleUploadErrorConnectionDisplay({ name });
      }
    };

    handleSingleFileUpload = async (myFile) => {
      myFile.id = uuidv4();
      const { name } = myFile;
      const { extension, shortName } = fixTrim(name, 20);
      this.singleFileUploadResetElements(name, shortName);

      await this.handleSingleFileReaderLoaded({
        myFile,
        shortName,
        extension,
        name,
      });
    };

    initUploadButton = () => {
      if (this.$w('#button1').label === '') {
        this.$w('#button1').label = t('app.widget.button.label');
      }
      this.$w('#button1').onClick(() => {
        logger.report(
          fileShareNewFileClick({
            app_id: controllerConfig.appParams.appDefinitionId,
            app_site_id: controllerConfig.appParams.appInstanceId,
            instance_id: controllerConfig.appParams.instanceId,
            is_first: firstClick,
            biToken: controllerConfig.platformAPIs.bi.metaSiteId,
            origin: 'upload_button',
            owner: userAPI.currentUser.role,
          }),
        );
        firstClick = false;
      });
    };

    hideAllElements() {
      this.hideAndCollapseElements([
        'popoverUp',
        'wrapContainer',
        'popoverButton1',
        'popoverFiles1',
        'popoverFilesError',
        'Disable',
        'MultiFiles',
        'SingleFile',
        'SingleLoading',
        'tooltip',
        'ShowFolderName',
        'text5',
        'vectorImage6',
      ]);
    }
    getAndSendAllowedExtensions = async () => {
      if (isAPIAllowed && permission?.status === Status.ALLOWED) {
        supportedExtensions = (await getSupportedExtensions(flowAPI))
          .map((val) => '.' + val)
          .join(',');

        this.$w('#uploadCustomElement').setAttribute(
          'supportedExtensions',
          supportedExtensions,
        );
      }
    };
    handleElementsMustBeAMember = () => {
      try {
        this.showAndExpandElements(['Disable', 'text6', 'wrapContainer']);
        this.$w('#Disable').onClick(async () => {
          logger.report(
            fileShareUploadButtonState({
              app_site_id: controllerConfig.appParams.appInstanceId,
              instance_id: controllerConfig.appParams.instanceId,
              biToken: controllerConfig.platformAPIs.bi.metaSiteId,
              state: 'log_in',
              action_type: 'click_on_action',
            }),
          );
          if (!userAPI.currentUser.loggedIn) {
            userAPI.promptLogin({});
          }
        });
        const text6 = this.$w('#text6');
        (text6 as any).richText = `<u>${t(
          'app.widget.message.not.loggedin',
        )}</u>`;
      } catch (e) {
        console.log(e);
      }
    };
    togglePopoverDisplay = () => {
      if (
        this.$w('#popoverFiles1').hidden ||
        this.$w('#popoverFilesError').hidden
      ) {
        this.showAndExpandElements(['popoverFiles1']);
        this.showAndExpandElements(['popoverFilesError']);
      } else {
        this.hideAndCollapseElements(['popoverFiles1']);
        this.hideAndCollapseElements(['popoverFilesError']);
      }
    };
    handleElementsNoPermissions = () => {
      try {
        this.showAndExpandElements(['Disable', 'wrapContainer', 'text6']);
        const text6 = this.$w('#text6');
        (text6 as any).richText = t('app.widget.message.no.permissions');
        this.$w('#UploadButton').onMouseIn(() => {
          this.$w('#popoverButton1').showNoPermissionPop();
          this.showAndExpandElements(['popoverButton1', 'popoverUp']);
        });
        this.$w('#UploadButton').onMouseOut(() => {
          this.hideAndCollapseElements(['popoverButton1']);
        });
      } catch (e) {
        console.log(e);
      }
    };
    showMultiFilesComponent = (files) => {
      this.$w('#MultiFiles').changeState('MultiSuccess');
      this.showAndExpandElements([
        'wrapContainer',
        'MultiFiles',
        'MultiSuccess',
        'FilesSelected',
        'popoverFiles1',
        'MultiError',
        'FileSelectedError',
        'popoverFilesError',
      ]);
      this.$w('#FilesSelected').label = t('app.widget.upload.select.folders', {
        numberFilesSelected: files.length,
      });
      if (!this.openPopoverFiles) {
        this.$w('#FilesSelected').onClick(() => {
          this.togglePopoverDisplay();
        });
        this.$w('#FileSelectedError').onClick(() => {
          this.togglePopoverDisplay();
        });
        this.openPopoverFiles = !this.openPopoverFiles;
      }
    };
    handleErrorTooManyFiles = (files) => {
      this.$w('#MultiFiles').changeState('MultiError');
      this.showAndExpandElements([
        'wrapContainer',
        'MultiFiles',
        'MultiError',
        'FileSelectedError',
        'text5',
        'vectorImage6',
      ]);
      this.hideAndCollapseElements(['FileSelectedError', 'popoverFilesError']);
      this.$w('#text5').text = t('app.widget.upload.error.limit.multi.folder');
      setTimeout(() => {
        this.$w('#vectorImage6').hide('fade');
        this.$w('#text5').hide('fade');
      }, 60000);
    };

    handleElementsUploadSuccessMultiFiles = async (files, uploader) => {
      const hasUploadFalse = files.some((item) => item.error === true);
      if (hasUploadFalse) {
        this.$w('#MultiFiles').changeState('MultiError');
      } else {
        this.$w('#MultiFiles').changeState('MultiSuccess');
      }
      this.$w('#FilesSelected').label = t('app.widget.upload.select.folders', {
        numberFilesSelected: files.length,
      });
      await this.$w('#popoverFiles1').uploadedDataFilePopover(
        files,
        uploader.file._id,
        uploader.file.upload,
        selectedFolderName,
        supportedExtensions,
      );
    };
    handleElementsUploadErrorMultiFiles = async (files, uploader) => {
      this.$w('#MultiFiles').changeState('MultiError');
      this.showAndExpandElements([
        'wrapContainer',
        'MultiFiles',
        'MultiError',
        'FileSelectedError',
        'popoverFilesError',
      ]);
      this.hideAndCollapseElements(['vectorImage6', 'text5']);

      this.$w('#FileSelectedError').label = t(
        'app.widget.upload.select.folders',
        {
          numberFilesSelected: files.length,
        },
      );
      await this.$w('#popoverFilesError').uploadDataMultiFilePopover(
        files,
        uploader.file._id,
        uploader.file.upload,
        selectedFolderName,
        supportedExtensions,
      );
    };
    handleMultiFilesReaderLoaded = async (uploader, files) => {
      clearTimeout(this.timers.multiple);
      const blob = new Blob([uploader.file as BlobPart], {
        type: uploader.type,
      });
      uploadsInProgress++;
      uploader.file.upload = UploadStatus.Progress;
      const uploadResult = await uploadFile(
        flowAPI,
        {
          name: uploader.file.name,
          size: uploader.file.size,
          myFile: blob,
        },
        selectedFolderId,
        this.onUploadProgress,
      );
      uploadsInProgress--;
      uploader.file.upload = UploadStatus.Finish;
      uploader.file.error = !uploadResult;
      if (uploadResult) {
        if (!isMultiError) {
          this.$w('#MultiFiles').changeState('MultiSuccess');
        }
        this.handleElementsUploadSuccessMultiFiles(files, uploader);
      } else {
        isMultiError = true;
        this.$w('#MultiFiles').changeState('MultiError');
        this.handleElementsUploadErrorMultiFiles(files, uploader);
      }
      if (uploadsInProgress === 0) {
        files.forEach((file) => {
          if (isMultiError) {
            this.handleElementsUploadErrorMultiFiles(files, { file });
          } else {
            this.handleElementsUploadSuccessMultiFiles(files, { file });
          }
        });
        this.hideUploadMultiSuccesAfterTime(20);
      }
    };
    handleMultiUploadOverlap = (hideElements, files) => {
      if (
        multiUploadSuccesFiles.length > 0 &&
        multiUploadSuccesFiles.length < 50
      ) {
        multiUploadSuccesFiles.push(...files);
        this.hideUploadSuccesafterTime(0);
      } else {
        multiUploadSuccesFiles.push(...files);
        this.hideUploadMultiSuccesAfterTime(0);
      }
      this.hideAndCollapseElements([hideElements]);
    };
    handleFilesAddedEvent = async (event) => {
      const { files } = event.detail;
      if (!files || !files.length) {
        return;
      }
      const arrFiles = Array.from(files).map((file: any) => {
        file._id = uuidv4();
        file.upload = UploadStatus.Start;
        file.error = false;
        return file;
      });
      const allUploaded = multiUploadSuccesFiles.every(
        (file) => file.upload === UploadStatus.Finish || false,
      );
      if (arrFiles.length === 1 && allUploaded) {
        this.handleMultiUploadOverlap('MultiFiles', arrFiles);
        this.handleSingleFileUpload(arrFiles[0]);
      } else if (arrFiles.length > 50) {
        this.handleMultiUploadOverlap('SingleFile', arrFiles);
        this.handleErrorTooManyFiles(arrFiles);
      } else {
        this.handleMultiUploadOverlap('SingleFile', arrFiles);
        if (!handleElementsUploadMultiFilesCalled) {
          this.handleElementsUploadMultiFiles(arrFiles);
          handleElementsUploadMultiFilesCalled = true;
        }
      }
    };

    handleElementsUploadMultiFiles = async (files) => {
      if (showDestinationFolder !== false) {
        const { data } = await queryFoldersApi(flowAPI, selectedFolderId);
        selectedFolderName =
          data.libraryItems?.[0]?.name ??
          t('app.widget.button.settings.main.folder');
      }
      try {
        const readers2 = multiUploadSuccesFiles.map((file: any) => ({
          file,
          type: file.type,
          _id: file._id ?? uuidv4(),
          name: file.name,
        }));

        this.showMultiFilesComponent(multiUploadSuccesFiles);
        const readers = files.map((file: any) => ({
          file,
          type: file.type,
          _id: file._id ?? uuidv4(),
          name: file.name,
        }));

        readers.forEach((uploader, index) => {
          uploader.file._id = uploader._id;
          uploader.file.upload = uploader.file.upload ?? UploadStatus.Start;
          uploader.file.error = uploader.file.error ?? false;
        });

        await this.$w('#popoverFiles1').showMultiFilesPopover(
          selectedFolderName,
          readers2,
          showDestinationFolder,
        );
        const chunks = chunk(readers, 5);

        await asyncForEach(chunks, async (chunkedPart) => {
          const promises = chunkedPart.map((uploader) =>
            this.handleMultiFilesReaderLoaded(uploader, multiUploadSuccesFiles),
          );

          await Promise.allSettled(promises);
        });
      } catch (e) {
        console.log(e);
      }
    };
    setPlansIds = async (aselectedFolderId) => {
      let pricingPlansIds = [];

      if (aselectedFolderId !== '') {
        const listRoles = await getListRoles(flowAPI, {
          ofCurrentUser: false,
          libraryItemId: aselectedFolderId,
          siteVisitors: false,
        });
        if (listRoles?.data && listRoles.data?.roles) {
          pricingPlansIds = listRoles.data.roles
            .filter(
              (role: any) =>
                role.roleType === roles.roleType &&
                (role.level === roles.level.MANAGE ||
                  role.level === roles.level.CONTRIBUTE) &&
                role?.permissions &&
                role.permissions?.length,
            )
            .map((role: any) => role.id);
        }
      }
      return pricingPlansIds;
    };
    navigateToPricingPlansApp = async (pricingPlansIds: any) => {
      const { relativeUrl }: any = await wixCodeApi?.site?.getSectionUrl({
        sectionId: 'membership_plan_picker_tpa',
        appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
      });
      return (
        relativeUrl +
        '?appSectionParams=' +
        encodeURIComponent(
          JSON.stringify({
            planIds: pricingPlansIds.join(','),
          }),
        )
      );
    };
    checkAndHandleElementsNoPricingPlanPop = async (
      aselectedFolderId,
    ): Promise<Boolean> => {
      try {
        let navigateToPricingPlans;
        this.showAndExpandElements(['Disable', 'wrapContainer']);
        const pricingPlansIds = await this.setPlansIds(aselectedFolderId);
        if (pricingPlansIds) {
          navigateToPricingPlans = await this.navigateToPricingPlansApp(
            pricingPlansIds,
          );
        }
        if (pricingPlansIds.length) {
          this.showAndExpandElements(['vectorImage7', 'text6']);
          this.$w('#text6').onClick(() => {
            logger.report(
              fileShareUploadButtonState({
                app_site_id: controllerConfig.appParams.appInstanceId,
                instance_id: controllerConfig.appParams.instanceId,
                biToken: controllerConfig.platformAPIs.bi.metaSiteId,
                state: 'paid_plan_details',
                action_type: 'click_on_action',
              }),
            );
          });
          const text6 = this.$w('#text6');
          (text6 as any).richText = t('app.widget.message.no.pricing.plan', {
            startTag: `<u><a href="${navigateToPricingPlans}">`,
            endTag: '</a></u>',
          });
        }
        return pricingPlansIds.length > 0;
      } catch (e) {
        console.log(e);
      }
      return false;
    };

    checkPermissions = async (): Promise<boolean> => {
      let result = false;
      permission = await handleUploadPermissions(
        this.$w,
        flowAPI,
        selectedFolderId,
      );
      if (permission?.status === Status.FORBIDDEN) {
        if (hideWhenNoPermissions !== true) {
          switch (permission.reason) {
            case Reason.MUST_BE_A_MEMBER:
              this.handleElementsMustBeAMember();
              break;
            case Reason.MISSING_PERMISSION:
              const isPricingPlanIssue =
                await this.checkAndHandleElementsNoPricingPlanPop(
                  selectedFolderId,
                );

              if (!isPricingPlanIssue) {
                this.handleElementsNoPermissions();
              }
              break;
            default:
              result = false;
          }
        } else {
          this.hideAndCollapseElements(['UploadButton']);
        }
      } else {
        if ('enable' in this.$w('#button1')) {
          this.$w('#button1').enable();
          this.hideAndCollapseElements(['Disable']);
          this.showAndExpandElements(['button1']);
          result = true;
          this.getAndSendAllowedExtensions();
          if (showDestinationFolder !== false) {
            const { data } = await queryFoldersApi(flowAPI, selectedFolderId);
            selectedFolderName =
              data.libraryItems?.[0]?.name ??
              t('app.widget.button.settings.main.folder');
          }
        }
      }
      return result;
    };
    checkOnMouseTooltip = (element) => {
      this.$w(element).onMouseIn(() => {
        this.showAndExpandElements(['tooltip', 'text10']);
      });
      this.$w(element).onMouseOut(() =>
        this.hideAndCollapseElements(['tooltip', 'text10']),
      );
    };
  }
  return {
    pageReady: async ($w) => {
      $widget.fireEvent('widgetLoaded', {});
      let shouldEnable = false;
      if ('disable' in $w('#button1')) {
        $w('#button1').disable();
      }
      const { props } = $widget;
      const controller = new createControllerFromReady($w);

      controller.hideAllElements();
      selectedFolderId = props.selectedFolderId;

      if (isAPIAllowed && selectedFolderId === '') {
        const { rootFolderId } = await getFileshareSettings(flowAPI);
        selectedFolderId = rootFolderId;
      }
      showDestinationFolder = props.showDestinationFolder;
      hideWhenNoPermissions = props.hideWhenNoPermissions;

      controller.initUploadButton();

      if (isAPIAllowed) {
        shouldEnable = await controller.checkPermissions();
        $w('#uploadCustomElement').on(
          'fileAdded',
          controller.handleFilesAddedEvent,
        );
      }
      userAPI.onLogin(async (event) => {
        await controller.checkPermissions();
      });
      ['#SingleLoading', '#SingleSuccess', '#SingleError'].forEach((val) =>
        controller.checkOnMouseTooltip(val),
      );
      if (shouldEnable || !isAPIAllowed) {
        if ('enable' in $w('#button1')) {
          $w('#button1').enable();
        }
      }
    },
    exports: {},
  };
});
