import { formatBytes } from '../Widget1/model';
import { fixTrim } from '../helpers/helpersFunctions';
import model from './model';
import { fileSize } from '../../constants/constants';
import ElementsVisibility from '../helpers/elementsVisibility';
import { UploadStatus } from '../../types';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  let uploadFileNumber = 0;
  const {
    translations: { t },
  } = flowAPI;
  const elementsVisibility = new ElementsVisibility($w);
  const { showAndExpandElements, hideAndCollapseElements } = elementsVisibility;

  const uploadDataMultiFilePopover = async (
    // Error mode
    returnedData,
    fileId,
    uploaded,
    selectedFolderName,
    supportedExtensions,
  ) => {
    $w('#repeaterFiles').data = Array.from(returnedData);
    $w('#repeaterFiles').onItemReady(($item, itemData, index) => {
      $item('#loadingFileName').text = fixTrim(itemData.name, 20).shortName;
      if (fileId !== itemData._id || !uploaded) {
        return;
      }
      const repeatedElementError = $item('#errorFileName');
      const name = itemData.name;
      const { extension } = fixTrim(name, 20);
      $item('#text1').text = name;
      repeatedElementError.text = fixTrim(itemData.name, 20).shortName;

      if (itemData.error) {
        const size = itemData.size;
        $item('#multiStateBox1').changeState('Error');
        showAndExpandElements(['Error']);
        const repeatedDescriptionElementError = $item('#errorDescriptionText');
        checkOnMouseTooltip('#errorFileName', $item);

        if (
          name.indexOf('.') === -1 ||
          !supportedExtensions.includes(extension)
        ) {
          repeatedDescriptionElementError.text = t(
            'app.widget.upload.multi.error.extenstion.file',
            {
              fileType: extension,
            },
          );
        } else if (size > fileSize.maxSize) {
          repeatedDescriptionElementError.text = t(
            'app.widget.upload.multi.error.size.file',
          );
        } else {
          repeatedDescriptionElementError.text = t(
            'app.widget.upload.error.connection.single.folder',
          );
        }
        const successes = returnedData.filter(
          (file) => !file.error && file.upload === UploadStatus.Finish,
        ).length;
        $w('#textNotification').text = t(
          'app.widget.upload.notification.error.files',
          {
            uploadFiles: successes,
            numberErrorFiles: returnedData.length,
            selectedFolderName,
          },
        );
      } else {
        $item('#multiStateBox1').changeState('Success');
        uploadFileNumber++;
        $item('#text1').text = itemData.name;
        const repeatedElementSuccess = $item('#successFileName');
        repeatedElementSuccess.text = fixTrim(itemData.name, 20).shortName;
      }
    });
    return returnedData;
  };
  const uploadedDataFilePopover = async (
    returnedData,
    fileId,
    uploaded,
    selectedFolderName,
  ) => {
    $w('#repeaterFiles').data = Array.from(returnedData);
    $w('#repeaterFiles').onItemReady(($item, itemData, index) => {
      $item('#loadingFileName').text = fixTrim(itemData.name, 20).shortName;
      if (fileId === itemData._id && itemData.upload === UploadStatus.Finish) {
        $item('#text1').text = itemData.name;
        const repeatedElementSuccess = $item('#successFileName');
        repeatedElementSuccess.text = fixTrim(itemData.name, 20).shortName;
        uploadFileNumber++;
        if (!itemData.error) {
          $item('#multiStateBox1').changeState('Success');

          showAndExpandElements(['Success']);

          checkOnMouseTooltip('#successFileName', $item);
          const successes = returnedData.filter(
            (file) => !file.error && file.upload === UploadStatus.Finish,
          ).length;
          $w('#textNotification').text = t(
            'app.widget.upload.notification.success.files',
            {
              uploadFiles: successes,
              numberSuccessFiles: returnedData.length,
              selectedFolderName,
            },
          );
        } else {
          $item('#multiStateBox1').changeState('Error');
        }
      }
    });
    return returnedData;
  };
  const showMultiFilesPopover = async (
    selectedFolderName,
    files,
    showDestinationFolder,
  ) => {
    try {
      if ('expand' in $w('#popoverFiles')) {
        showAndExpandElements(['popoverFiles']);
      }
      if ('expand' in $w('#repeaterFiles')) {
        $w('#multiStateBox1').changeState('Loading');
        hideAndCollapseElements(['Tooltip']);
        showAndExpandElements([
          'box1',
          'repeaterFiles',
          'nameFileRepeater',
          'multiStateBox1',
          'Loading',
        ]);
        if (showDestinationFolder) {
          if ('expand' in $w('#notificationBox')) {
            showAndExpandElements(['notificationBox']);
          }
          if ('expand' in $w('#textNotification')) {
            showAndExpandElements(['textNotification']);
          }

          const successes = files.filter(
            (file) => file.upload === UploadStatus.Finish && !file.error,
          ).length;
          $w('#textNotification').text = t(
            'app.widget.upload.notification.success.files',
            {
              uploadFiles: successes,
              numberSuccessFiles: files.length,
              selectedFolderName,
            },
          );
        } else {
          hideNotificationBox();
        }
        $w('#repeaterFiles').data = Array.from(files);
        showAndExpandElements(['Loading']);
        $w('#repeaterFiles').onItemReady(($item, itemData) => {
          $item('#text1').text = itemData.name;

          $item('#multiStateBox1').changeState('Loading');
          checkOnMouseTooltip('#loadingFileName', $item);

          $item('#loadingFileName').text = fixTrim(itemData.name, 20).shortName;
        });
        uploadFileNumber = 0;
      }
    } catch (e) {
      console.log('Popover error occurd => showMultiFilesPopover()', e);
    }
  };
  const hideNotificationBox = () => {
    hideAndCollapseElements(['notificationBox']);
  };
  const checkOnMouseTooltip = (element, parentElement) => {
    parentElement(element).onMouseIn(() => {
      showAndExpandElements(['Tooltip', 'text1'], parentElement);
    });
    parentElement(element).onMouseOut(() =>
      hideAndCollapseElements(['Tooltip', 'text1'], parentElement),
    );
  };

  return {
    pageReady: async (_$w) => {
      $widget.fireEvent('widgetLoaded', {});
      hideAndCollapseElements(['Tooltip']);
      _$w('#repeaterFiles').data = [];
    },
    exports: {
      showMultiFilesPopover,
      hideNotificationBox,
      uploadedDataFilePopover,
      uploadDataMultiFilePopover,
    },
  };
});
