import model from './model';
import ElementsVisibility from '../helpers/elementsVisibility';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const {
    translations: { t },
  } = flowAPI;
  const elementsVisibility = new ElementsVisibility($w);
  const { showAndExpandElements } = elementsVisibility;

  const showNoPermissionPop = async () => {
    try {
      $w('#text1').text = t('app.widget.message.no.permissions.tooltip');
      if ('expand' in $w('#popoverButton')) {
        showAndExpandElements(['popoverButton']);
      }
      if ('expand' in $w('#text1')) {
        showAndExpandElements(['text1']);
      }
    } catch (e) {
      console.log('Button Popover error occurd => showNoPermissionPop()', e);
    }
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      showNoPermissionPop,
    },
  };
});
