export const fileSize = {
  maxSize: 524288000,
};
export const roles = {
  roleType: 'PLAN',
  level: {
    MANAGE: 'MANAGE',
    CONTRIBUTE: 'CONTRIBUTE',
  },
};
