export default class ElementsVisibility {
  #$w: any = null;
  constructor($w) {
    this.#$w = $w;
  }
  showAndExpandElements = (elementIds, parentElement = null) => {
    parentElement = parentElement ?? this.#$w;
    elementIds.forEach((elementId) => {
      try {
        parentElement(`#${elementId}`).restore();
        parentElement(`#${elementId}`).show();
        parentElement(`#${elementId}`).expand();
      } catch (e) {
        console.log('error in showAndExpandElements', e, elementId);
      }
    });
  };

  hideAndCollapseElements = (elementIds, parentElement = null) => {
    parentElement = parentElement ?? this.#$w;
    elementIds.forEach((elementId) => {
      try {
        parentElement(`#${elementId}`).hide();
        parentElement(`#${elementId}`).collapse();
        parentElement(`#${elementId}`).delete();
      } catch (e) {
        console.log('error in hideAndCollapseElements', e, elementId);
      }
    });
  };
}
