export const fixTrim = (name, maxLength) => {
  const shortNameParts = name.split('.');
  const extension = shortNameParts.pop();

  if (name.length < maxLength) {
    return { shortName: name, extension };
  }

  let shortName = shortNameParts.join('.');
  shortName =
    shortName.slice(0, maxLength - extension.length - 3) + '...' + extension;
  return { shortName, extension };
};

export async function asyncForEach(
  array: any[],
  callback: (value: any, index: number, array: any[]) => Promise<void>,
): Promise<void> {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }

  return Promise.resolve();
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
